import { useEffect, useState } from "react";
import {byteCTA_MintTaskClaim, byteCTA_PostTaskRegister, byteCTA_GetTaskStatus} from "./utils/bytecta";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';
import { BarLoader } from "react-spinners";
import urlJoin from 'url-join';
import Alert from "react-bootstrap/Alert";

const Task1 = (props) => {

  const contractAddress = props.contractAddress;
  const blockExplorerUrl = props.blockExplorerUrl;
  const contractABI = props.contractABI;
  const chainDisplayName = props.chainDisplayName;

  const byteCTA_ApiUrlRegister = props.byteCTA_ApiUrlRegister; 
  const byteCTA_ApiUrlStatus = props.byteCTA_ApiUrlStatus; 
  const byteCTA_ApiFunctionKey = props.byteCTA_ApiFunctionKey;

  const byteCTA_ApiKey = props.byteCTA_ApiKey;
  const byteCTA_TaskT1Enabled = props.byteCTA_TaskT1Enabled;    
  const byteCTA_TaskT1Message = props.byteCTA_TaskT1Message;
  const byteCTA_TaskT1MessagePopover = props.byteCTA_TaskT1MessagePopover;
  const byteCTA_TaskT1Key = props.byteCTA_TaskT1Key;  
  
  const [walletAddress, setWalletAddress] = useState("");
  const [walletNetworkCorrect, setWalletNetworkCorrect] = useState("");    

  const [taskT1SectionEnabled, setTaskT1SectionEnabled] = useState(false);
  const [taskT1SectionSaveHandleEnabled, setTaskT1SectionSaveHandleEnabled] = useState(true);
  const [taskT1SectionMessageEnabled, setTaskT1SectionMessageEnabled] = useState(false);
  const [taskT1SectionClaimNFTEnabled, setTaskT1SectionClaimNFTEnabled] = useState(false);

  const [handle, setHandle] = useState("");
  const [handleSaveButtonDisabled, setHandleSaveButtonDisabled] = useState(true);
  const [handleSaved, setHandleSaved] = useState(false);
  const [taskT1ClaimApproved, setTaskT1ClaimApproved] = useState(false);
  const [taskT1ClaimCompleted, setTaskT1ClaimCompleted] = useState(false);
  const [claimToken, setClaimToken] = useState("");
  const [claimTokenID, setClaimTokenID] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);

  const statusMessage = {
    wrongNetwork: "Wrong Network",
    connectWallet: "Connect Your Wallet",
  };

  useEffect(() => { 
    if(!byteCTA_TaskT1Enabled){
      setTaskT1SectionEnabled(false);
    }else{
      setTaskT1SectionEnabled(true);       

      if(handleSaved){
        setTaskT1SectionSaveHandleEnabled(false);
        setTaskT1SectionMessageEnabled(true);
      }else{
        setTaskT1SectionSaveHandleEnabled(true);
        setTaskT1SectionMessageEnabled(false);
      }  
      
      if(taskT1ClaimApproved && !taskT1ClaimCompleted){          
        setTaskT1SectionClaimNFTEnabled(true);
        setTaskT1SectionSaveHandleEnabled(false);
        setTaskT1SectionMessageEnabled(false);
      }else if(taskT1ClaimCompleted){
        setTaskT1SectionClaimNFTEnabled(false);
        setTaskT1SectionSaveHandleEnabled(false);
        setTaskT1SectionMessageEnabled(false);          
      }else{
        setTaskT1SectionClaimNFTEnabled(false);
      }               
    }      
  }, [taskT1ClaimApproved, taskT1ClaimCompleted, byteCTA_TaskT1Enabled, handleSaved]);
    
  useEffect(() =>{
    setWalletAddress(props.walletAddress);
    setWalletNetworkCorrect(props.walletNetworkCorrect);

  }, [props.walletAddress, props.walletNetworkCorrect]);
    
  async function onMintTransactionUpdateAsync(success, status, txHash){
    setIsMinting(false);
    if(success){
      props.setAlertVariant("success");   
      props.setStatus(getTransactionAlertStatusProcessing(txHash, true));
      setTaskT1ClaimCompleted(true);
      
      setTimeout(props.setMyTokensRefresh(Math.random()), 5000);
    }else{
      props.setAlertVariant("danger");  
      props.setStatus(status);
    }
  }

  const onMintTaskPressed = async () => {

    let statusMintPressed = "";
    props.setStatus(statusMintPressed);
    
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true){

      if(taskT1ClaimApproved && !taskT1ClaimCompleted){
        //Mint
        //Set status
        setIsMinting(true);
        const mintResult = await byteCTA_MintTaskClaim(contractABI, contractAddress, claimTokenID, claimToken, onMintTransactionUpdateAsync);
        statusMintPressed = mintResult.status;
        
        if(mintResult.success){
          props.setAlertVariant("info");
          props.setStatus(getTransactionAlertStatusProcessing(mintResult.txHash, false));
        }else{
          setIsMinting(false);
          props.setAlertVariant("danger");
          if(statusMintPressed.includes("Already Claimed")){
            setTaskT1ClaimCompleted(true);
            props.setStatus("Already Claimed");
          }else{
            props.setStatus(statusMintPressed);
          }
          console.log(statusMintPressed)

        }             
      }          
    }else if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect !== true){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }    
  };

  const onHandleSaveButtonPressed = async () => {
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true){
      setIsRegistering(true);

      let postTaskResult = await byteCTA_PostTaskRegister(byteCTA_ApiFunctionKey, byteCTA_ApiUrlRegister, byteCTA_ApiKey, handle, byteCTA_TaskT1Key);

      if(postTaskResult.success){
        props.setAlertVariant("success"); 
        (postTaskResult.handlePreviouslySaved)?(props.setStatus("Already Registered")):(props.setStatus("Registration Successful"));                     
        setHandle(postTaskResult.handle);
        setHandleSaved(postTaskResult.handleSaved);
        setTaskT1ClaimApproved(postTaskResult.taskClaimApproved);
        setTaskT1ClaimCompleted(postTaskResult.taskClaimCompleted);
        setClaimToken(postTaskResult.claimToken);               
      }else{
        props.setAlertVariant("danger");     
        console.log(postTaskResult.status) ;
        if(postTaskResult.status.includes("Registration Already Exists")){
          props.setStatus("Registration Already Exists, Try Check Status");   
        }else if(postTaskResult.status.includes("Handle Already Registered")){
          props.setStatus("Handle Already Registered, Try Check Status");   
        }else{
          props.setStatus(postTaskResult.status);           
        }                    
      }
      setIsRegistering(false);
    }
    else if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect !== true){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }        
  }; 

  const onCheckStatusButtonPressed = async () => {
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true){
      setIsCheckingStatus(true);

      let postTaskResult = await byteCTA_GetTaskStatus(byteCTA_ApiFunctionKey, byteCTA_ApiUrlStatus, byteCTA_ApiKey, byteCTA_TaskT1Key, true);

      props.setStatus(postTaskResult.status);

      if(postTaskResult.success){
        props.setAlertVariant("success");
        if(postTaskResult.taskClaimCompleted){
          props.setStatus("Already Claimed");
        }
        else if(postTaskResult.taskClaimApproved){
          props.setStatus("Ready To Claim");
        }else{
          props.setStatus("Waiting for your X (Tweet). Verification (could take up to 3 days)");
        }
        setHandle(postTaskResult.handle);
        setHandleSaved(postTaskResult.handleSaved);
        setTaskT1ClaimApproved(postTaskResult.taskClaimApproved);
        setTaskT1ClaimCompleted(postTaskResult.taskClaimCompleted);
        setClaimToken(postTaskResult.claimToken);   
        setClaimTokenID(postTaskResult.tokenID);
      }else{
        props.setAlertVariant("danger");
        if(postTaskResult.status.includes("Registration Not Found")){
          props.setStatus("Must Register First");           
        }else{
          props.setStatus(postTaskResult.status);           
        }  
      } 
      setIsCheckingStatus(false);
    }
    else if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect !== true){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }        
  };       

  const onHandleChange = event => {
    const validRegExHandle = new RegExp("^[a-zA-Z0-9_]{1,15}$");

    if(!event.target.value){
      setHandle("");
      setHandleSaveButtonDisabled(true);
    }
    if(validRegExHandle.test(event.target.value)){
      setHandle(event.target.value);
      setHandleSaveButtonDisabled(false);
    }
  };  
  
  const messagePopover = (
    <Popover id="message-popover">
      <Popover.Body>
        <div dangerouslySetInnerHTML={{__html: byteCTA_TaskT1MessagePopover}}></div>
      </Popover.Body>
    </Popover>
  );
  
  function getTransactionAlertStatusProcessing(txHash, isComplete){
    const txHashURL = urlJoin(blockExplorerUrl, "tx", txHash);
    const statusMessageTransactionReceipt = {
        statusSuccess: (
            <div>
                <Alert.Heading>Success</Alert.Heading>
                See Transaction:                 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
        statusProcessing: (
            <div>
                <Alert.Heading>Processing</Alert.Heading>
                See Transaction: 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
    };      
    
    if(isComplete){
      return statusMessageTransactionReceipt.statusSuccess;
    }else{
      return statusMessageTransactionReceipt.statusProcessing;
    }
  }

  return(
  <div className={taskT1SectionEnabled ? ("") : ("d-none")} >
      <h2 className="mt-5">Task: X (Twitter) -{">"} Claim NFT</h2>
      <ul>
        <li>Save your handle to register</li>
        <li>Address and handle may register 1 time, and cannot be changed</li>
        <li>X (Tweet) this 
          <OverlayTrigger trigger="click" placement="right" overlay={messagePopover}>
            <Button id="supportButton" className="ms-2 me-2">message</Button>
          </OverlayTrigger>
          using the button below
        </li>
        <li>Check Status, and claim your free {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire X (Twitter) NFT (x500 presence multiplier)</li>
        <li>You may claim this reward once per chain</li>
      </ul>
        <div>
          <div className="d-flex">@
            <input
              type="text"
              placeholder="myHandle"
              onChange={onHandleChange}
              value={handle}
              disabled={!taskT1SectionSaveHandleEnabled}
            />
          </ div>
          {!taskT1SectionSaveHandleEnabled ? ("") : (
          <div className="d-inline-block">
            {(isRegistering || isCheckingStatus)? (<BarLoader color="#f3b033" className="mt-3 ms-3"></BarLoader>) :
            (
            <div>
              <Button 
              className="mt-3"
              variant="primary" 
              onClick={onHandleSaveButtonPressed} 
              disabled= {handleSaveButtonDisabled}
              >
                Save Handle         
              </Button>
              <Button 
              className="mt-3 ms-3"
              variant="primary" 
              onClick={onCheckStatusButtonPressed} 
              >
                Check Status         
              </Button>
            </div>)}
          </div>             
          )} 
          {!taskT1SectionMessageEnabled ? ("") : (
          <div className="d-inline-block">
            <Button
              className="mt-3"
              variant="primary"
              href={`https://x.com/intent/tweet?text=${byteCTA_TaskT1Message}`}   
              target="_blank"
              rel="noreferrer"                           
            >
              X (Tweet)
            </Button>
            {isCheckingStatus ? (<BarLoader color="#f3b033" className="mt-3 ms-3"></BarLoader>) :
              (<Button 
              className="mt-3 ms-3"
              variant="primary" 
              onClick={onCheckStatusButtonPressed} 
            >
              Check Status         
            </Button>)}
          </div>             
          )} 
          {!taskT1SectionClaimNFTEnabled ? ("") : (
              isMinting ? (<BarLoader id="mintBarLoader" color="#f3b033"></BarLoader>) :
              (<Button 
                className="mt-3"
                variant="primary" 
                onClick={onMintTaskPressed}
                disabled= {!taskT1SectionClaimNFTEnabled} 
              >
                Claim NFT         
              </Button>)

          )} 
          {!taskT1ClaimCompleted ? ("") : (
          <Badge className="mt-2" bg="success">
            <h5 variant="success">Completed</h5>
          </Badge>
          )}             
        </div>          
    </div>            
  );
};

export default Task1;