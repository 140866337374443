import React, { useEffect, useState } from "react";
import { getMetadata } from "./utils/interact";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ReactPlayer from "react-player";


const TokenModal = (props) => {
    const uriMetadataBase =  props.uriMetadataBase;
    const [item, setItem] = useState([]);
    const [itemHover, setItemHover] = useState(false);

    useEffect(() => {
        const getMetadataFunction = async () => {
            setItem(await getMetadata(uriMetadataBase + props.tokenid));
        }

        if(props.tokenid){
            getMetadataFunction();
        }
    },[uriMetadataBase, props.tokenid]);

    return(
        <div className="TokenModal">
            <Modal
            {...props}
            centered
        >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Card onMouseEnter={(e) => setItemHover(true)} onMouseLeave={(e) => setItemHover(false)}>
                        {itemHover?(
                            <ReactPlayer className="position-absolute" url={item["uri-animation-https"]} width="100%" height="auto" playing={true} muted={true} loop={true} controls={false} playsinline={true}/>
                        ):("")}                           
                        <Card.Img variant="top" src={item["uri-image-https"]}/>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                                <p className="p-0 m-0">id: {item.id}</p>
                                <p className="p-0 m-0">period: {item['attribute-period']}</p>
                                <p className="p-0 m-0">version: {item['attribute-version']}</p>
                                <p className="p-0 m-0">presence boost: +{item['attribute-presencemultiplier']}</p>
                                <p className="p-0 m-0">task: {item['attribute-task']}</p>
                            </Card.Text>
                            image (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-image-ipfs"]}>ipfs</Card.Link>, 
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-image-https"]}>https</Card.Link>)
                            <br />
                            animation (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-animation-ipfs"]}>ipfs</Card.Link>, 
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-animation-https"]}>https</Card.Link>)
                            <br />
                            metadata (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-metadata-ipfs"]}>ifps</Card.Link>,
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-metadata-https"]}>https</Card.Link>)
                        </Card.Body>                      
                    </Card>
            </Modal.Body>
        </Modal> 
        </div>   
                 
    );
};

export default TokenModal;