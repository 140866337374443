import { FaXTwitter } from "react-icons/fa6";

function Footer() {
    return(
        <div className="float-end"> 
            <a target="_blank" href="https://x.com/blockchainBNFR" rel="noreferrer">
                <FaXTwitter />
            </a>
        </div>             
    );
};

export default Footer;