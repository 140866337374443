import { useEffect, useState } from "react";
import urlJoin from 'url-join';
import { getBalanceForAddressAggregate } from "./utils/interact";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ReactPlayer from "react-player";


const MyTokens = (props) => {

    const blockExplorerUrl = props.blockExplorerUrl;
    const contractAddress = props.contractAddress;
    const chainDisplayName = props.chainDisplayName;

    const [tokenBalanceData, setTokenBalanceData] = useState([]);

    useEffect(() =>{
        const getBalanceForAddressAggregateUseEffect = async () => {

            let getBalanceForAddressAggregateResult = [];

            if(props.address){
                getBalanceForAddressAggregateResult = (await getBalanceForAddressAggregate()).result;
            }

            setTokenBalanceData(getBalanceForAddressAggregateResult);
        }        

        getBalanceForAddressAggregateUseEffect();
    },[props.address, props.myTokensRefresh]);

    function myTokenMouseEnter(id) {
        let myTokenCardPlayerElement = document.getElementById(id);
        myTokenCardPlayerElement.classList.remove("d-none");

        let myTokenCardPlayerElementVideo = myTokenCardPlayerElement.firstChild;
        myTokenCardPlayerElementVideo.play();
    }

    function myTokenMouseLeave(id) {
        let myTokenCardPlayerElement = document.getElementById(id);
        myTokenCardPlayerElement.classList.add("d-none");

        let myTokenCardPlayerElementVideo = myTokenCardPlayerElement.firstChild;
        myTokenCardPlayerElementVideo.pause();
    }    

    try{
        let tokenDataListHeaderDisplay = 
        (tokenBalanceData.length > 0) ? 
            (
                <div>
                    <h2>My {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs</h2>
                    <p className="mb-0 d-inline-block">Contract:</p>
                    <a className="d-inline-block" target="_blank" href={urlJoin(blockExplorerUrl, "address", contractAddress)} rel="noreferrer">{contractAddress}</a>
                </div>
            )
        : ("")

        let tokenDataListDisplay = 
        (tokenBalanceData.length > 0) ? 
        tokenBalanceData.map((item, i) => {
                return (
                <Col key={i} className="pt-2 pb-2" sm={6} md={6} lg={4} xl={4} xxl={3}>
                    <Card onMouseEnter={() => myTokenMouseEnter("myTokenCardPlayer"+i)} onMouseLeave={() => myTokenMouseLeave("myTokenCardPlayer"+i)}>
                        <ReactPlayer id={"myTokenCardPlayer"+i} className="position-absolute" url={item["uri-animation-https"]} width="100%" height="auto" muted={true} loop={true} controls={false} playsinline={true}/>                        
                        <Card.Img variant="top" src={item["uri-image-https"]} />
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                                <span className="p-0 m-0">id: {item.id}</span>
                                <br />
                                <span className="p-0 m-0 ms-auto">amt owned: {Number(item.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })} {(item.amountSupply > 0)? (" of " + Number(item.amountSupply).toLocaleString(undefined, { maximumFractionDigits: 2 })) : ("")}</span> 
                                <br />
                                <span className="p-0 m-0">period: {item['attribute-period']}</span>
                                <br />
                                <span className="p-0 m-0">version: {item['attribute-version']}</span>
                                <br />
                                <span className="p-0 m-0">presence boost: +{item['attribute-presencemultiplier']}</span>
                                <br />
                                <span className="p-0 m-0">task: {item['attribute-task']}</span>
                            </Card.Text>
                            image (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-image-ipfs"]}>ipfs</Card.Link>, 
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-image-https"]}>https</Card.Link>)
                            <br />
                            animation (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-animation-ipfs"]}>ipfs</Card.Link>, 
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-animation-https"]}>https</Card.Link>)
                            <br />
                            metadata (<Card.Link className="p-0 m-0" target="_blank" href={item["uri-metadata-ipfs"]}>ifps</Card.Link>,
                            <Card.Link className="p-0 m-0" target="_blank" href={item["uri-metadata-https"]}>https</Card.Link>)
                        </Card.Body>                      
                    </Card>
                </Col>                    
                );
            })
        : ("")    
        return(
            <div className="MyTokens">
                {tokenDataListHeaderDisplay}
                <Row className="pt-4">
                    {tokenDataListDisplay}
                </Row>            
            </div>             
        );
    }catch (error){
        return("");
    }
};

export default MyTokens;