import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const Schedule = (props) => {


const renderTooltip_Days = (props) => (
    <Tooltip {...props}>Number of Days in the Period</Tooltip>
);
const renderTooltip_Total = (props) => (
    <Tooltip {...props}>Total Days since launch</Tooltip>
);
const renderTooltip_Year = (props) => (
    <Tooltip {...props}>Year</Tooltip>
);
const renderTooltip_Multiplier = (props) => (
    <Tooltip {...props}>Presence Multiplier (from NFT metadata)</Tooltip>
);

    return(
        <Table striped>
            <thead>
                <tr>
                    <th>Period</th>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip_Days}
                    >
                        <th>Days</th>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip_Total}
                    >
                        <th>Total</th>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip_Year}
                    >                                        
                    <th>Yr</th>
                    </OverlayTrigger>                    
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={renderTooltip_Multiplier}
                    >
                    <th>Multiplier</th>
                    </OverlayTrigger>                      
                </tr>
            </thead>
            <tbody className="p-0">
                <tr>
                <td>0</td>
                <td>20</td>
                <td>20</td>
                <td>0</td>
                <td>1500</td>
                </tr>
                <tr>
                <td>1</td>
                <td>20</td>
                <td>40</td>
                <td>0</td>
                <td>1100</td>
                </tr>
                <tr>
                <td>2</td>
                <td>20</td>
                <td>60</td>
                <td>0</td>
                <td>1000</td>
                </tr>
                <tr>
                <td>3</td>
                <td>20</td>
                <td>80</td>
                <td>0</td>
                <td>900</td>
                </tr>
                <tr>
                <td>4</td>
                <td>20</td>
                <td>100</td>
                <td>0</td>
                <td>720</td>
                </tr>
                <tr>
                <td>5</td>
                <td>20</td>
                <td>120</td>
                <td>0</td>
                <td>650</td>
                </tr>
                <tr>
                <td>6</td>
                <td>20</td>
                <td>140</td>
                <td>0</td>
                <td>580</td>
                </tr> 
                <tr>
                <td>7</td>
                <td>20</td>
                <td>160</td>
                <td>0</td>
                <td>510</td>
                </tr> 
                <tr>
                <td>8</td>
                <td>30</td>
                <td>190</td>
                <td>0</td>
                <td>440</td>
                </tr> 
                <tr>
                <td>9</td>
                <td>30</td>
                <td>220</td>
                <td>0</td>
                <td>390</td>
                </tr>
                <tr>
                <td>10</td>
                <td>30</td>
                <td>250</td>
                <td>0</td>
                <td>340</td>
                </tr> 
                <tr>
                <td>11</td>
                <td>30</td>
                <td>280</td>
                <td>0</td>
                <td>290</td>
                </tr> 
                <tr>
                <td>12</td>
                <td>30</td>
                <td>310</td>
                <td>0</td>
                <td>240</td>
                </tr> 
                <tr>
                <td>13</td>
                <td>30</td>
                <td>340</td>
                <td>0</td>
                <td>210</td>
                </tr> 
                <tr>
                <td>14</td>
                <td>30</td>
                <td>370</td>
                <td>1</td>
                <td>180</td>
                </tr>
                <tr>
                <td>15</td>
                <td>30</td>
                <td>400</td>
                <td>1</td>
                <td>150</td>
                </tr>
                <tr>
                <td>16</td>
                <td>40</td>
                <td>440</td>
                <td>1</td>
                <td>120</td>
                </tr>
                <tr>
                <td>17</td>
                <td>40</td>
                <td>480</td>
                <td>1</td>
                <td>100</td>
                </tr>
                <tr>
                <td>18</td>
                <td>40</td>
                <td>520</td>
                <td>1</td>
                <td>80</td>
                </tr>
                <tr>
                <td>19</td>
                <td>40</td>
                <td>560</td>
                <td>1</td>
                <td>60</td>
                </tr>
                <tr>
                <td>20</td>
                <td>40</td>
                <td>600</td>
                <td>1</td>
                <td>40</td>
                </tr>
                <tr>
                <td>21</td>
                <td>40</td>
                <td>640</td>
                <td>1</td>
                <td>30</td>
                </tr>
                <tr>
                <td>22</td>
                <td>40</td>
                <td>680</td>
                <td>1</td>
                <td>20</td>
                </tr>
                <tr>
                <td>23</td>
                <td>40</td>
                <td>720</td>
                <td>2</td>
                <td>10</td>
                </tr>
                <tr>
                <td>24</td>
                <td>*</td>
                <td>*</td>
                <td>*</td>
                <td>1</td>
                </tr> 
                <tr>
                <td>T1</td>
                <td></td>
                <td></td>
                <td></td>
                <td>500</td>
                </tr>
                <tr>
                <td>T2</td>
                <td></td>
                <td></td>
                <td></td>
                <td>1000</td>
                </tr>   
                <tr>
                <td>T3</td>
                <td></td>
                <td></td>
                <td></td>
                <td>10000</td>
                </tr>                                                                                                                                                                                                                                                                                                                                     
            </tbody>
        </Table>
    );
};

export default Schedule;