import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Minter from "./Minter";
import TokenModal from "./TokenModal";

function App() {
  const uriMetadataBase =  process.env.REACT_APP_TOKENMODAL_URI_METADATA_BASE;

  const [tokenModalShow, setTokenModalShow] = useState(false);
  const [searchParams] = useSearchParams();
  const [tokenID] = useState(searchParams.get("id"));

  useEffect(() => {

    (tokenID)?(setTokenModalShow(true)):(setTokenModalShow(false));

  },[tokenID]);

  return (
    <div className="App">
      <TokenModal
        show={tokenModalShow}
        tokenid={tokenID}
        onHide={() => setTokenModalShow(false)}
        uriMetadataBase={uriMetadataBase}
      />         
      <Container>
        <Row>
          <Minter/>
        </Row>
      </Container>
    </div>
  );
}

export default App;
