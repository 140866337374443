import axios from "axios";
import detectEthereumProvider from "@metamask/detect-provider";
const Web3 = require("web3");
const contractABI = require("../lib/contract-abi.json");

const chainsHex =  process.env.REACT_APP_CHAINSHEX.toLowerCase().split(",");
const chainsName =  process.env.REACT_APP_CHAINSNAME.toLowerCase().split(",");

const statusMessage = {
    installMetamask: "Install Metamask",
    wrongNetwork: "Wrong Network",
};

const getIsWalletValid = async () => {
    var isValid = false;
    const provider = await detectEthereumProvider({
        mustBeMetaMask: true
      });

    if(provider !== null){   
        try
        {
            if(navigator.userAgent.toLowerCase().includes("metamaskmobile")){

            }else if(provider.providers.length > 1){
                window.ethereum = await window.ethereum.providers.find( (provider) => provider.isMetaMask );
                window.ethereum.on("accountsChanged", () => {
                    window.location.reload();
                });   
                window.ethereum.on("chainChanged", () => {
                    window.location.reload();
                }); 
                                              
            }
        }catch{}
        isValid = true;
    }    
    return {
        isValid: isValid,
    }; 
}

export const connectWallet = async() => {
     if (window.ethereum && (await getIsWalletValid()).isValid) {
        let status = "";
        try{
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const walletNetworkCorrect = chainsHex.includes((await getChainId()).chainId);
            
            if (walletNetworkCorrect !== true){
                status = statusMessage.wrongNetwork;
            }
            return{
                status: status,
                address: addressArray[0],
                walletNetworkCorrect: walletNetworkCorrect,                
            };
        } catch (err) {
            return {
                address: "",
                status: err.message,
                walletNetworkCorrect: false,

            };    
        }
    } else {
        return{
            address: "",
            status: statusMessage.installMetamask,
            walletNetworkCorrect: false,

        };
    }
};

export const getCurrentWalletConnected = async() => {
    if (window.ethereum  && (await getIsWalletValid()).isValid) {
        
        let walletNetworkCorrect = false;
        let status = "";

        if(chainsHex.includes((await getChainId()).chainId)){
            walletNetworkCorrect = true;
        }
        else{
            status = statusMessage.wrongNetwork;
        }
        try{
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });

            
            if (addressArray.length > 0){
                const walletEthBalance = (await getWalletEthBalance()).walletEthBalance;   
                return {
                    address: addressArray[0],
                    status: status,
                    walletEthBalance: walletEthBalance,
                    walletNetworkCorrect: walletNetworkCorrect,
                };
            } else {
                return {
                    address: "",
                    status: status,
                    walletEthBalance: "",
                    walletNetwork: "",
                    walletNetworkCorrect: walletNetworkCorrect,
                };                
            }
        } catch (err) {
            return {
                address: "",
                status: status + "|" + err.message,
                walletEthBalance: "",
                walletNetwork: "",
                walletNetworkCorrect: walletNetworkCorrect,
            }    
        }
    } else {
        return{
            address: "",
            status: statusMessage.installMetamask,
            walletEthBalance: "",
            walletNetwork: "",
            walletNetworkCorrect: false,

        };
    }
};

export const switchEthereumChain = async(_chainName) => {
    if (window.ethereum  && (await getIsWalletValid()).isValid) {

        const hexChainID = chainsHex[chainsName.indexOf(_chainName)];

        try{
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: hexChainID}],
            });
            return {
                walletNetworkCorrect: true,
            };               
        } catch (err) {
            if (err.code === 4902){
                try{
                    const getChainDetailsByNameResult = await getChainDetailsByName(_chainName);
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: getChainDetailsByNameResult.hexChainID,
                                chainName: getChainDetailsByNameResult.chainName,
                                nativeCurrency: {
                                    name: getChainDetailsByNameResult.currencyName,
                                    symbol: getChainDetailsByNameResult.currencySymbol,
                                    decimals: getChainDetailsByNameResult.currencyDecimals,
                                  },
                                rpcUrls: [getChainDetailsByNameResult.rpcUrl],
                                blockExplorerUrls: [getChainDetailsByNameResult.blockExplorerUrl]
                            },
                        ],
                    });  
                    return {
                        walletNetworkCorrect: true,
                    };              
                }
                catch (addError){
                    console.log(addError.message);
                    return {
                        status: addError.message,
                        walletNetworkCorrect: false,
                    };                       
                } 
            }
            else{
                console.log(err.message);
                return {
                    status: err.message,
                    walletNetworkCorrect: false,
                };                
            }
        }
    } 
};

export const getEnvVariablesMinter = async () => {
    let chainName = "";
    let contractAddress = "";
    let blockExplorerUrl = "";
    let chainDisplayName = "";
    let currencySymbol = "";
    let byteCTA_ApiUrlRegister = "";
    let byteCTA_ApiUrlStatus = "";
    let byteCTA_ApiFunctionKey = "";
    let byteCTA_ApiKey = "";
    let byteCTA_TaskT1Enabled = false;
    let byteCTA_TaskT1Message = "";
    let byteCTA_TaskT1MessagePopover = "";
    let byteCTA_TaskT1Key = "";
    let taskT2Enabled = false;
    let taskT2TokenID = null;
    let taskT3Enabled = false;
    let taskT3TokenID = null;
    let byteCTA_TaskT3Key = "";

    if (window.ethereum  && (await getIsWalletValid()).isValid) { 

        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });

        if(addressArray.length > 0){
            chainName = chainsName[chainsHex.indexOf((await getChainId()).chainId)];
            if(chainName){
                contractAddress = process.env[("REACT_APP_CONTRACTADDRESS_".concat(chainName))];
                blockExplorerUrl = process.env[("REACT_APP_BLOCKEXPLORERURL_".concat(chainName))];
                chainDisplayName = process.env[("REACT_APP_DISPLAYNAME_".concat(chainName))];
                currencySymbol = process.env[("REACT_APP_CURRENCYSYMBOL_".concat(chainName))];
                byteCTA_ApiUrlRegister = process.env.REACT_APP_BYTECTA_API_URL_REGISTER;
                byteCTA_ApiUrlStatus = process.env.REACT_APP_BYTECTA_API_URL_STATUS;
                byteCTA_ApiFunctionKey = process.env.REACT_APP_BYTECTA_API_FUNCTION_KEY;
                byteCTA_ApiKey = process.env[("REACT_APP_BYTECTA_API_KEY_".concat(chainName))];
                byteCTA_TaskT1Enabled = Boolean((process.env[("REACT_APP_BYTECTA_TASK_T1_ENABLED_".concat(chainName))].toLowerCase()==="true")?true:"");
                byteCTA_TaskT1Message = process.env.REACT_APP_BYTECTA_TASK_T1_MESSAGE.replace("{byteCTA_Tags}", process.env[("REACT_APP_BYTECTA_TASK_T1_MESSAGE_SUB_".concat(chainName))]).replace("{byteCTA_TaskTagText}", process.env.REACT_APP_BYTECTA_TASK_HASHTAGTEXT);
                byteCTA_TaskT1MessagePopover = process.env.REACT_APP_BYTECTA_TASK_T1_MESSAGE_POPOVER.replace("{byteCTA_Tags}", process.env[("REACT_APP_BYTECTA_TASK_T1_MESSAGE_POPOVER_SUB_".concat(chainName))]).replace("{byteCTA_TaskTagText}", process.env.REACT_APP_BYTECTA_TASK_HASHTAGTEXT); 
                byteCTA_TaskT1Key = process.env[("REACT_APP_BYTECTA_TASK_T1_KEY_".concat(chainName))];  
                taskT2Enabled = Boolean((process.env.REACT_APP_TASK_T2_ENABLED.toLowerCase()==="true")?true:"");
                taskT2TokenID = Number(process.env.REACT_APP_TASK_T2_TOKENID);
                taskT3Enabled = Boolean((process.env.REACT_APP_TASK_T3_ENABLED.toLowerCase()==="true")?true:"");
                taskT3TokenID = Number(process.env.REACT_APP_TASK_T3_TOKENID);
                byteCTA_TaskT3Key = process.env[("REACT_APP_BYTECTA_TASK_T3_KEY_".concat(chainName))];  
            }
        }
    }
    return {
        chainName: chainName,
        contractAddress: contractAddress,
        blockExplorerUrl: blockExplorerUrl,
        chainDisplayName: chainDisplayName,
        currencySymbol: currencySymbol,
        byteCTA_ApiUrlRegister: byteCTA_ApiUrlRegister,
        byteCTA_ApiUrlStatus: byteCTA_ApiUrlStatus,
        byteCTA_ApiFunctionKey: byteCTA_ApiFunctionKey,
        byteCTA_ApiKey: byteCTA_ApiKey,
        byteCTA_TaskT1Enabled: byteCTA_TaskT1Enabled,
        byteCTA_TaskT1Message: byteCTA_TaskT1Message,
        byteCTA_TaskT1MessagePopover: byteCTA_TaskT1MessagePopover,
        byteCTA_TaskT1Key: byteCTA_TaskT1Key,
        taskT2Enabled: taskT2Enabled,
        taskT2TokenID: taskT2TokenID,
        taskT3Enabled: taskT3Enabled,
        taskT3TokenID: taskT3TokenID,
        byteCTA_TaskT3Key: byteCTA_TaskT3Key
    }; 
}

const getChainName = async () => {
    let chainName = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {
        chainName = chainsName[chainsHex.indexOf((await getChainId()).chainId)];
    }
    return {
        chainName: chainName,
    }; 
}

const getChainId = async () => {
    if (window.ethereum && (await getIsWalletValid()).isValid) {

        const chainId = await window.ethereum.request({
            method: "eth_chainId",
        })
        return {
            chainId: chainId.toLowerCase(),
        }; 
    }
}

const getContractAddress = async () => {
    const chainName = chainsName[chainsHex.indexOf((await getChainId()).chainId)];
    const contractAddress = process.env[("REACT_APP_CONTRACTADDRESS_".concat(chainName))];
    return {
        contractAddress: contractAddress
    }; 
}

const getChainDetailsByName = async (_chainName) => {
    const contractAddress = process.env[("REACT_APP_CONTRACTADDRESS_".concat(_chainName))];
    const hexChainID = process.env[("REACT_APP_HEXCHAINID_".concat(_chainName))];
    const chainName = process.env[("REACT_APP_CHAINNAME_".concat(_chainName))];
    const currencyName =  process.env[("REACT_APP_CURRENCYNAME_".concat(_chainName))];
    const currencySymbol =  process.env[("REACT_APP_CURRENCYSYMBOL_".concat(_chainName))];
    const currencyDecimals =  Number(process.env[("REACT_APP_CURRENCYDECIMALS_".concat(_chainName))]);
    const rpcUrl =  process.env[("REACT_APP_RPCURL_".concat(_chainName))];
    const blockExplorerUrl =  process.env[("REACT_APP_BLOCKEXPLORERURL_".concat(_chainName))];
    return {
        contractAddress: contractAddress,
        hexChainID: hexChainID,
        chainName: chainName,
        currencyName: currencyName,
        currencySymbol: currencySymbol,
        currencyDecimals: currencyDecimals,
        rpcUrl: rpcUrl,
        blockExplorerUrl: blockExplorerUrl
    }; 
}

const getSelectedAddressChecksum = async () => {
    if (window.ethereum && (await getIsWalletValid()).isValid) {
        var web3 = new Web3(window.ethereum); 
        return web3.utils.toChecksumAddress((await window.ethereum.request({ method: 'eth_accounts' }))[0]);
    }
}

export const getWalletEthBalance = async () => {

    if (window.ethereum && (await getIsWalletValid()).isValid) {

        const walletEthBalance = Web3.utils.fromWei(await window.ethereum.request({
            method: "eth_getBalance",
            params: [window.ethereum.selectedAddress, "latest"]
        }))  
        return {
            walletEthBalance: walletEthBalance,
        }; 
    }else{
        return {
            walletEthBalance: "",
        };         
    }
}

export const isMintAmountValid = async (amount) => {
    let statusString = "";
    let successResult = false;
    const currencySymbol =  process.env[("REACT_APP_CURRENCYSYMBOL_".concat((await getChainName()).chainName))];
    const walletEthBalance = Number((await getWalletEthBalance()).walletEthBalance);     
    const mintCost = Number((await getMintCost("ether")).result);
    const mintCostTotal = Number(mintCost * amount);

    if (walletEthBalance < mintCostTotal){ 
        statusString = "You must have >= "+ mintCostTotal + " " + currencySymbol + " in your wallet";
        successResult = false;
      
    }else if (amount.trim().length === 0 || amount < 1){
        statusString = "Enter an amount >= 1 and <= "+Number(walletEthBalance / mintCost).toLocaleString(undefined, { maximumFractionDigits: 2 });
        successResult = false;
    }
    else{
        successResult = true;
    }   
    
    return {
        success: successResult,
        status: statusString,
    };     
}

export const mint = async (amount, onMintTransactionUpdateAsync) => {
    if (window.ethereum && (await getIsWalletValid()).isValid) {

        const contractAddress = (await getContractAddress()).contractAddress;
        const isMintAmountValidResult = await isMintAmountValid(amount);
        if(isMintAmountValidResult.success === false){
            return {
                success: isMintAmountValidResult.success,
                status: isMintAmountValidResult.status,
            };          
        }    
        
        const mintCost = Number((await getMintCost()).result);
        const mintCostTotal = Number(mintCost * amount);        
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        const transactionParameters = {
            to: contractAddress, 
            from: await getSelectedAddressChecksum(),
            //value: web3.utils.numberToHex(web3.utils.toWei(mintCostTotal)),
            value: web3.utils.numberToHex(mintCostTotal),
            data: window.contract.methods.mint().encodeABI()
        };

        try {
            
            const txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
            
            if (txHash!=null){
                const obj = await getTransactionReceipt(txHash, onMintTransactionUpdateAsync);
                return{
                        success: obj.success,
                        status: obj.status,
                        txHash: txHash
                }
            }else{
                return {
                    success: false,
                    status: "Error: No transaction exists"
                }            
            }
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }    
}

export const mint_TaskBonus = async (id, onMintTransactionUpdateAsync) => {

    if (window.ethereum && (await getIsWalletValid()).isValid) {

        const contractAddress = (await getContractAddress()).contractAddress;   
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        const transactionParameters = {
            to: contractAddress, 
            from: await getSelectedAddressChecksum(),
            data: window.contract.methods.mint_TaskBonus(id).encodeABI(),
        };

        try {
            const txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
            if (txHash!=null){
                const obj = await getTransactionReceipt(txHash, onMintTransactionUpdateAsync);
                return{
                        success: obj.success,
                        status: obj.status,
                        txHash: txHash
                }
            }else{
                return {
                    success: false,
                    status: "Error: No transaction exists"
                }            
            }
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }    
}

const getTransactionReceipt = async (txHash, onMintTransactionUpdateAsync) => {
    
    var web3 = new Web3(window.ethereum); 
    
    let success = true;
    let status = "";

    const interval = await setInterval(()=>{ 
        web3.eth.getTransactionReceipt(txHash, function(error, rec){
        if (rec) {
            success = true;
            status = "";  

            onMintTransactionUpdateAsync(success, status, txHash);
            clearInterval(interval);
            return{
                success: success,
                status: status,
                txHash: txHash
            };            
        } else if(error) {
            success = false;
            status = "Error: " + error.message; 
            onMintTransactionUpdateAsync(success, status, txHash);
            return{
                success: success,
                status: status,
                txHash: txHash
            };                        
        }
        });
    }, 1000);   
    return{
        success: success,
        status: status,
        txHash: txHash
    };       
}

export const getBalanceForAddressAggregate = async () => {
    let statusString = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getBalanceForAddressAggregate(await getSelectedAddressChecksum()).call();

            let resultEnriched = [];
            for (let i = 0; i < result.length; i++) {

                let arrayItem = await getMetadata(result[i].url);
                arrayItem.amount = result[i].amount;
                arrayItem.amountSupply = result[i].amountSupply;

                resultEnriched.push(arrayItem);  
            }

            return{
                success: true,
                status: statusString,
                result: resultEnriched
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }    
}

export const getMetadata = async (uriIPFS) => {
    let arrayItem = {};
    try{
        let metadataStringHttps = uriIPFS.toString().replace("ipfs://", "https://ipfs.io/ipfs/");
        let resultMetadata = await axios.get(metadataStringHttps);

        arrayItem = {
            "amount" : 0,
            "amountSupply" : 0,
            "id" : resultMetadata.data.id,
            "uri-metadata-ipfs" : uriIPFS,
            "uri-metadata-https" : metadataStringHttps,
            "uri-animation-ipfs" : resultMetadata.data.animation_url,
            "uri-animation-https" : resultMetadata.data.animation_url.replace("ipfs://", "https://ipfs.io/ipfs/"),
            "uri-image-ipfs" : resultMetadata.data.image,
            "uri-image-https" : resultMetadata.data.image.replace("ipfs://", "https://ipfs.io/ipfs/"),                
            "description" : resultMetadata.data.description,
            "uri-external" : resultMetadata.data.external_url,
            "name" : resultMetadata.data.name,
            "attribute-version" : resultMetadata.data.attributes[0].value,
            "attribute-period" : resultMetadata.data.attributes[1].value,
            "attribute-task" : resultMetadata.data.attributes[2].value,
            "attribute-presencemultiplier" : resultMetadata.data.attributes[3].value
        } 
    }catch(err){}
        
        return arrayItem;
    }

export const getPeriod = async () => {
    let statusString = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getPeriod().call();

            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }    
}

export const getPeriodTimeRemaining = async () => {
    let statusString = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getPeriodTimeRemaining().call();

            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }      
}

export const getTotalSupply = async () => {
    let statusString = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.totalSupply().call();

            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }      
}

export const getTotalAddressesAtBonfire = async () => {
    let statusString = "";
    
    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getTotalAddressesAtBonfire().call();

            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }    
}

export const getStats = async () => {
    let statusString = "";
    
    if (window.ethereum  && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
    
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getStats().call();
            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }
}

export const getAddressMintDetails = async (id) => {
    let statusString = "";

    if (window.ethereum && (await getIsWalletValid()).isValid) {

        const contractAddress = (await getContractAddress()).contractAddress;

        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);
        
        try {
            let result = await window.contract.methods.getAddressMintTaskData(await getSelectedAddressChecksum(), id).call();
            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            console.log(error);
            return {
                success: false,
                status: "Error: " + error.message
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }
    
}

// Default in Wei
// Pass
export const getMintCost = async (unit) => {
    let statusString = "";
    if (window.ethereum && (await getIsWalletValid()).isValid) {
        const contractAddress = (await getContractAddress()).contractAddress;
        var web3 = new Web3(window.ethereum); 
        window.contract = new web3.eth.Contract(contractABI, contractAddress);

        try {
            let result = await window.contract.methods.getMintCost().call();
            if(unit === "ether"){
                result = web3.utils.fromWei(result,"ether");
            }
            return{
                success: true,
                status: statusString,
                result: result
            };
            
        } catch (error) {
            console.log("getMintCost error "+ error);
            var errorResult = 0;
            if(unit === "ether"){
                errorResult = 1;
            }else{
                errorResult = web3.utils.toWei(1);
            }

            return {
                success: false,
                status: "Error: " + error.message,
                result: errorResult
            }
        }
    }else{
        return {
            success: false,
            status: "Error: Invalid Provider"
        }
    }      
}
