import axios from "axios";
const Web3 = require("web3");

export const byteCTA_MintTaskClaim = async (contractABI, contractAddress, claimTokenID, claimToken, onMintTransactionUpdateAsync) => {
   
    var web3 = new Web3(window.ethereum); 
    window.contract = new web3.eth.Contract(contractABI, contractAddress);

    const transactionParameters = {
        to: contractAddress, 
        from: await getSelectedAddressChecksum(),
        data: window.contract.methods.byteCTA_MintTaskClaim(claimTokenID, claimToken).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
        if (txHash!=null){
            const obj = await byteCTA_GetTransactionReceipt(txHash, onMintTransactionUpdateAsync);
            return{
                    success: obj.success,
                    status: obj.status,
                    txHash: txHash
            }
        }else{
            return {
                success: false,
                status: "Error: No transaction exists"
            }            
        }
    } catch (error) {
        return {
            success: false,
            status: "Error: " + error.message
        }
    }
}

const byteCTA_GetTransactionReceipt = async (txHash, onMintTransactionUpdateAsync) => {
    
    var web3 = new Web3(window.ethereum); 
       
    let success = true;
    let status = "";

    const interval = await setInterval(()=>{ 
        web3.eth.getTransactionReceipt(txHash, function(error, rec){
        if (rec) {
            success = true;
            status = "";

            onMintTransactionUpdateAsync(success, status, txHash);
            clearInterval(interval);
            return{
                success: success,
                status: status,
                txHash: txHash
            };            
        } else if(error) {
            success = false;
            status = "Error: " + error.message; 
            onMintTransactionUpdateAsync(success, status, txHash);
            return{
                success: success,
                status: status,
                txHash: txHash
            };                        
        }
        });
    }, 1000);   
    return{
        success: success,
        status: status,
        txHash: txHash
    };       
}

export const byteCTA_GetTaskStatus = async (byteCTA_ApiFunctionKey, byteCTA_ApiUrlStatus, byteCTA_ApiKey, taskKey, isSigned) => {
    if (window.ethereum) {
        try{
            var web3 = new Web3(window.ethereum); 
            const address = await getSelectedAddressChecksum();
            var metadata;

            if(isSigned){
                const data = await web3.utils.soliditySha3(address, taskKey);
                const signature = await web3.eth.personal.sign(data, address);
                metadata = {
                    address: address,
                    taskKey: taskKey,
                    hashedData: data,
                    signature: signature
                };
            }else{
                metadata = {
                    address: address,
                    taskKey: taskKey
                };                
            }

            return await axios.post(byteCTA_ApiUrlStatus,
                metadata,
                {
                    headers: {
                        "X-API-Key": byteCTA_ApiKey,
                        "X-Functions-Key": byteCTA_ApiFunctionKey,
                        "Content-Type":"application/json",
                        "accept":"application/json"
                    }
                }                 
            )
            .then( async (res) => {
                return{
                    success: true,
                    handle: res.data.handle,
                    handleSaved: res.data.handleSaved,
                    taskClaimApproved: res.data.taskClaimApproved,
                    taskClaimCompleted: res.data.taskClaimCompleted,
                    claimToken: res.data.claimToken,
                    tokenID: res.data.tokenID
                };
            })
            .catch((error) =>{
                let errorMsg = (error.request.response.length === 0)?(error.message):(error.request.response)
                return {
                    success: false,
                    status: "Error: " + errorMsg
                };
            });               
        }catch(error){
            return {
                success: false,
                status: "Error: " + error.message
            };
        }       
    }
}

export const byteCTA_PostTaskRegister = async (byteCTA_ApiFunctionKey, byteCTA_ApiUrlRegister, byteCTA_ApiKey, handle, taskKey) => {
    if (window.ethereum) {
        try{
            var web3 = new Web3(window.ethereum); 
            const address = await getSelectedAddressChecksum();
            const data = await web3.utils.soliditySha3(address, handle, taskKey);
            const signature = await web3.eth.personal.sign(data, address);

            return await axios.post(byteCTA_ApiUrlRegister,
                {
                    address: address,
                    handle: handle,
                    taskKey: taskKey,
                    hashedData: data,
                    signature: signature                    
                },
                {
                    headers: {
                        "X-API-Key": byteCTA_ApiKey,
                        "X-Functions-Key": byteCTA_ApiFunctionKey,
                        "Content-Type":"application/json",
                        "accept":"application/json"
                    }
                }                 
            )
            .then((res) =>{
                return{
                    success: true,
                    handle: res.data.handle,
                    handleSaved: res.data.handleSaved,
                    handlePreviouslySaved: res.data.handlePreviouslySaved,
                    taskClaimApproved: res.data.taskClaimApproved,
                    taskClaimCompleted: res.data.taskClaimCompleted,
                    claimToken: res.data.claimToken                    
                };
            })
            .catch((error) =>{
                let errorMsg = (error.request.response.length === 0)?(error.message):(error.request.response)
                return {
                    success: false,
                    status: "Error: " + errorMsg
                };
            });   
        }catch(error){

            return{
                success: false,
                status: "Error: " + error.message
            }
        }
    }
}

export const byteCTA_AddressHasClaimed = async (contractABI, contractAddress, id) => {
    let statusString = "";

    var web3 = new Web3(window.ethereum); 
    window.contract = new web3.eth.Contract(contractABI, contractAddress);

    try {
        let result = await window.contract.methods.byteCTA_AddressHasClaimed(await getSelectedAddressChecksum(), id).call();
        return{
            success: true,
            status: statusString,
            result: result
        };
        
    } catch (error) {
        console.log(error);
        return {
            success: false,
            status: "Error: " + error.message
        }
    }
}

const getSelectedAddressChecksum = async () => {
    if (window.ethereum) { 
        var web3 = new Web3(window.ethereum); 
        return web3.utils.toChecksumAddress((await window.ethereum.request({ method: 'eth_accounts' }))[0]);
    }
}