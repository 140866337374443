import { useEffect, useState } from "react";
import {getStats} from "./utils/interact";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';



const Stats = (props) => {

    const showStatsLimit = Number(props.showStatsLimit);
    const chainDisplayName = props.chainDisplayName;
    const currencySymbol = props.currencySymbol;

    const [period, setPeriod] = useState("");
    const [periodTimeRemaining, setPeriodTimeRemaining] = useState(0);
    const [totalSupply, setTotalSupply] = useState("");
    const [totalAddressesAtBonfire, setTotalAddressesAtBonfire] = useState("");
    const [totalBurnt, setTotalBurnt] = useState("");
    const [showStats, setShowStats] = useState(false);


    useEffect(() =>{               

        const getStatsUseEffect = async () => {
            let result = (await getStats());

            if(result.success === true){
                setTotalSupply(result.result.totalSupplyAll);
                setShowStats((result.result.totalSupplyAll > showStatsLimit)?true:false);

                setPeriod(result.result.period);
                setTotalAddressesAtBonfire(result.result.totalAddressesAtBonfire);
                setTotalBurnt(result.result.totalInBonfire);
                setPeriodTimeRemaining(Number(result.result.periodTimeRemaining));    

            }
            else{
                setTotalSupply("");
                setShowStats(false);

                setPeriod("");
                setTotalAddressesAtBonfire("");
                setTotalBurnt("");
                setPeriodTimeRemaining(0);                      
            }
        }

        getStatsUseEffect();

    },[props.myTokensRefresh, showStatsLimit]);

    return(
        <div className="Stats"> 
            <Row className="mt-4 pb-5">
                {(period)?(<Col className="pt-2 pb-2 justify-content-center" sm={12} md={6} lg={6} xl={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-center">
                                Period
                            </Card.Title>
                            <Card.Text className="mb-0 d-flex justify-content-center">
                                {Number(period).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Card.Text>
                            <Card.Text className="d-flex justify-content-center">
                                {(periodTimeRemaining===0)?(""):(Number(periodTimeRemaining).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " days remain" )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>):("")}
                {(showStats)?(
                <Col className="pt-2 pb-2" sm={12} md={6} lg={6} xl={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-center">
                                Participants
                            </Card.Title>
                            <Card.Text className="d-flex justify-content-center">
                                {Number(totalAddressesAtBonfire).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>  
                ):("")}
                {(showStats)?(
                <Col className="pt-2 pb-2" sm={12} md={6} lg={6} xl={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-center">
                                Total {currencySymbol === "" ? "tokens" : currencySymbol} Burnt
                            </Card.Title>
                            <Card.Text className="d-flex justify-content-center">
                                {Number(totalBurnt).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                ):("")}
                {(showStats)?(
                <Col className="pt-2 pb-2" sm={12} md={6} lg={6} xl={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-center">
                                Total {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs
                            </Card.Title>
                            <Card.Text className="d-flex justify-content-center">
                                {Number(totalSupply).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                ):("")}
            </Row>                    
        </div>             
    );
};

export default Stats;