import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { BarLoader } from "react-spinners";
import urlJoin from 'url-join';
import { connectWallet, getCurrentWalletConnected, mint, switchEthereumChain, getWalletEthBalance, isMintAmountValid, getEnvVariablesMinter, getMintCost} from "./utils/interact";
import MyTokens from "./MyTokens";
import Stats from "./Stats";
import Schedule from "./Schedule";
import Task1 from "./Task1";
import Task2 from "./Task2";
import Task3 from "./Task3";

import Footer from "./Footer";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from 'react-bootstrap/Dropdown';

const Minter = (props) => {

  // Env Variables
  const showStatsLimit = process.env.REACT_APP_STATS_SHOWSTATSLIMIT;  
  const chainsName =  process.env.REACT_APP_CHAINSNAME.toLowerCase().split(",");
  
  const contractABI = require("./lib/contract-abi.json");
  const [contractAddress, setContractAddress] = useState("");
  const [blockExplorerUrl, setBlockExplorerUrl] = useState("");
  const [chainDisplayName, setChainDisplayName] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [byteCTA_ApiUrlRegister, setByteCTA_ApiUrlRegister] = useState("");
  const [byteCTA_ApiUrlStatus, setByteCTA_ApiUrlStatus] = useState("");
  const [byteCTA_ApiFunctionKey, setByteCTA_ApiFunctionKey] = useState("");
  
  const [byteCTA_ApiKey, setByteCTA_ApiKey] = useState("");
  const [byteCTA_TaskT1Enabled, setByteCTA_TaskT1Enabled] = useState(false);
  
  const [byteCTA_TaskT1Message, setByteCTA_TaskT1Message] = useState("");
  const [byteCTA_TaskT1MessagePopover, setByteCTA_TaskT1MessagePopover] = useState("");
  const [byteCTA_TaskT1Key, setByteCTA_TaskT1Key] = useState("");

  const [taskT2Enabled, setTaskT2Enabled] = useState(false);
  const [taskT2TokenID, setTaskT2TokenID] = useState(26);

  const [byteCTA_TaskT3Enabled, setByteCTA_TaskT3Enabled] = useState(false);
  const [taskT3TokenID, setTaskT3TokenID] = useState(27);
  const [byteCTA_TaskT3Key, setByteCTA_TaskT3Key] = useState("");

  const mediaLogo32 = "bbnfr-logo-32.png";
  const mediaTokenFilePath = "bbnfr-token-v1-util-white-closeup.mp4" ;

  //State variables
  const [walletAddress, setWalletAddress] = useState("");
  const [status, setStatus] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");

  const [amount, setAmount] = useState("");
  const [mintCost, setMintCost] = useState("");

  const [walletEthBalance, setWalletEthBalance] = useState("");
  const [walletNetworkCorrect, setWalletNetworkCorrect] = useState("");
 
  const [walletButtonShake, setWalletButtonShake] = useState("");
  const [myTokensRefresh, setMyTokensRefresh] = useState(Math.random());
  const [isMinting, setIsMinting] = useState(false);

  const [chainName, setChainName] = useState("");

  const statusMessage = {
    installMetamask: (
      <div>
        <Alert.Link target="_blank" href={`https://metamask.io/download.html`} rel="noreferrer">
          Install Metamask
        </Alert.Link>
        <p className="mb-1">If mobile, use built-in&nbsp;
          <Alert.Link target="_blank" href={`https://support.metamask.io/hc/en-us/articles/6356387482523-How-to-use-the-MetaMask-Mobile-Browser`} rel="noreferrer">Metamask browser</Alert.Link>
        </p>
      </div>
      ),
    wrongNetwork: "Wrong Network",
    connectWallet: "Connect Your Wallet",
  };

  useEffect(() => { 

    const getCurrentWalletConnectedUseEffect = async () => {
      const {address, status, walletEthBalance, walletNetworkCorrect} = await getCurrentWalletConnected();
      (status === "Install Metamask")? setStatus(statusMessage.installMetamask) : setStatus(status);   
      setWalletAddress(address);
      setWalletEthBalance(walletEthBalance);
      setWalletNetworkCorrect(walletNetworkCorrect); 

      const getEnvVariablesMinterResult = (await getEnvVariablesMinter());
      setChainName(getEnvVariablesMinterResult.chainName);
      setContractAddress(getEnvVariablesMinterResult.contractAddress);
      setBlockExplorerUrl(getEnvVariablesMinterResult.blockExplorerUrl);
      setChainDisplayName(getEnvVariablesMinterResult.chainDisplayName);
      setCurrencySymbol(getEnvVariablesMinterResult.currencySymbol);
      setByteCTA_ApiUrlRegister(getEnvVariablesMinterResult.byteCTA_ApiUrlRegister);
      setByteCTA_ApiUrlStatus(getEnvVariablesMinterResult.byteCTA_ApiUrlStatus);
      setByteCTA_ApiFunctionKey(getEnvVariablesMinterResult.byteCTA_ApiFunctionKey);
      setByteCTA_ApiKey(getEnvVariablesMinterResult.byteCTA_ApiKey);
      setByteCTA_TaskT1Enabled(getEnvVariablesMinterResult.byteCTA_TaskT1Enabled);
      setByteCTA_TaskT1Message(getEnvVariablesMinterResult.byteCTA_TaskT1Message);
      setByteCTA_TaskT1MessagePopover(getEnvVariablesMinterResult.byteCTA_TaskT1MessagePopover);
      setByteCTA_TaskT1Key(getEnvVariablesMinterResult.byteCTA_TaskT1Key);
      setTaskT2Enabled(getEnvVariablesMinterResult.taskT2Enabled);
      setTaskT2TokenID(Number(getEnvVariablesMinterResult.taskT2TokenID));
      setByteCTA_TaskT3Enabled(getEnvVariablesMinterResult.taskT3Enabled);
      setTaskT3TokenID(Number(getEnvVariablesMinterResult.taskT3TokenID));
      setByteCTA_TaskT3Key(getEnvVariablesMinterResult.byteCTA_TaskT3Key);

      const getMintCostResult = (await getMintCost("ether"));
      setMintCost(getMintCostResult.result);
    }
    
    getCurrentWalletConnectedUseEffect();
  }, []);

  useEffect(() => { 
    addWalletListener(); 
    addChainChanged();
  }, []);  

  useEffect(() => {

    if(status){
      setAlertShow(true);

      if(walletNetworkCorrect !== true){
        setAlertVariant("danger");
      } 
    }else{
      setAlertShow(false);
      setAlertVariant("danger");
    }

  }, [status, walletNetworkCorrect]);

  const connectWalletPressed = async () => {
    const connectWalletResponse = await connectWallet();
    // (connectWalletResponse.status === "Install Metamask")? setStatus(statusMessage.installMetamask) : setStatus(connectWalletResponse.status);   
    // setWalletAddress(connectWalletResponse.address);
    // setWalletNetworkCorrect(connectWalletResponse.walletNetworkCorrect); 
    window.location.reload();
  }; 

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });      
    } 
  };

  function addChainChanged() {
    if (window.ethereum) {
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });
    } 
  };

  function getTransactionAlertStatusProcessing(txHash, isComplete){
    const txHashURL = urlJoin(blockExplorerUrl, "tx", txHash);
    const statusMessageTransactionReceipt = {
        statusSuccess: (
            <div>
                <Alert.Heading>Success</Alert.Heading>
                See Transaction:                 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
        statusProcessing: (
            <div>
                <Alert.Heading>Processing</Alert.Heading>
                See Transaction: 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
    };      
        
    if(isComplete){
      return statusMessageTransactionReceipt.statusSuccess;
    }else{
      return statusMessageTransactionReceipt.statusProcessing;
    }
  }        

  async function onMintTransactionUpdateAsync(success, status, txHash){
    setWalletEthBalance((await getWalletEthBalance()).walletEthBalance);
    setIsMinting(false);

    if(success){
      setAlertVariant("success");
      setStatus(getTransactionAlertStatusProcessing(txHash, true));

      setTimeout(setMyTokensRefresh(Math.random()), 5000);
    }else{
      setAlertVariant("danger");  
      setStatus(status);      
    }

  }

  const onMintPressed = async () => {

    let statusMintPressed = "";
    setStatus(statusMintPressed);

    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true){
      const isMintAmountValidResult = await isMintAmountValid(amount);
      
      if(isMintAmountValidResult.success){

        setIsMinting(true);
        const mintResult = await mint(amount, onMintTransactionUpdateAsync);
        statusMintPressed = mintResult.status;
        
        if(mintResult.success){
          setAmount("");
          setAlertVariant("info");
          setStatus(getTransactionAlertStatusProcessing(mintResult.txHash, false));
        }else{
          setIsMinting(false);
          setAlertVariant("danger");
          setStatus(statusMintPressed);
        }
      }else{
        setStatus(isMintAmountValidResult.status);
        setAlertVariant("danger");
      }
    } else if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect !== true){
        setStatus(statusMessage.wrongNetwork);
        setAlertVariant("danger");
    } else if (window.ethereum){
        setWalletButtonShake("shakeElement");
        setStatus(statusMessage.connectWallet);
    } else {
        setWalletButtonShake("shakeElement");
        setStatus(
          statusMessage.installMetamask
        );
        setAlertVariant("warning");
    }
  };

  const onMaxPressed = event => {
    if (window.ethereum && walletAddress.trim().length !== 0 && walletEthBalance >= mintCost){
      setAmount(String(Math.trunc(walletEthBalance/mintCost)));
    } 
    event.preventDefault();
  };  
  
  const onHandleAmountChange = event => {
    const amountChanged = event.target.value.replace(/\D/g, '');
    setAmount(amountChanged);
  };
  
  const schedulePopover = (
    <Popover id="schedule-popover">
      <Popover.Body>
        <Schedule />
      </Popover.Body>
    </Popover>
  );  

  const onChainNameChange = async(event) => {
    if(chainsName.includes(event)){
      let result = (await switchEthereumChain(event));

      if(result.walletNetworkCorrect){
        setChainName(event);
      }else{
        setWalletButtonShake("shakeElement");
      }
    }
  };

  return (
    <div className="Minter">
      <div>
        <div>        
          <div>
            <Button 
              variant="outline-primary" 
              className={`float-end ms-2 ${walletButtonShake}`}
              onClick={connectWalletPressed} 
              onAnimationEnd={() => setWalletButtonShake("")}
              active={walletAddress.length > 0 ? true : false}
            >
              {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}            
            </Button>
          </div>
          <div className="float-end">
            <Dropdown onSelect={onChainNameChange}>
              <Dropdown.Toggle variant="outline-secondary">
                {
                  (!chainName) ? "" : (
                    <img src={"chain-icon-"+chainName+".png"} className="chain-icon" alt="logo" loading="lazy"/>
                  )
                }
                {chainName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {chainsName.map((element, key) => {
                  return(
                    <Dropdown.Item active={element === chainName} key={key} eventKey={element}>
                      <img src={"chain-icon-"+element+".png"} className="chain-icon" alt="logo"  />
                      {element}</Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>                      
        </div>
        <br />    
        <Alert id="status" className="position-fixed" show={alertShow} variant={alertVariant} onClose={() => {setAlertShow(false); setStatus("");}} dismissible>          
          {status}
        </Alert>      
      </div>
      <h1 className="pt-8">
        <img src={mediaLogo32} className="title-logo" alt="logo" />
        {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire
      </h1>
      <p>
        We are a collective gathering at the {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire. Some are here to party, whilst others for better parties to come. Partake in the quest to explore Cartesian NFTs. What's a Cartesian NFT?...when paths across chains point to common coordinates of interest, a digital asset, a Cartesian NFT.
      </p>   
      <p>
        Mint your {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFT tokens on {chainDisplayName === "" ? "your favorite chains" : "the " + chainDisplayName + " chain"} to complete your Cartesian NFT.
      </p>
      <Row>
        <Col sm={12} md={12} lg={8} xl={7} xxl={7}>
          <div>
              <p className="mt-2 d-inline-block">{chainDisplayName === "" ? "" : "Contract:"}</p>
              <a className="d-inline-block" target="_blank" href={urlJoin(blockExplorerUrl, "address", contractAddress)} rel="noreferrer">{contractAddress}</a>                
          </div>
          <ul>
            <li>
              There are 25 Periods (0 - 24)
            </li>
            <li>
              Each {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFT is minted to the current Period
            </li>            
            <li>
              Periods are scheduled:               
              <OverlayTrigger trigger="click" placement="right" overlay={schedulePopover}>
                <Button id="supportButton" className="ms-2">
                  schedule and multiplier
                </Button> 
              </OverlayTrigger>
            </li>                                      
            <li>
              Period 0 starts on the first Mint
            </li>
            <li>
              Mint on all chains to complete your Cartesian NFT
            </li>                             
          </ul>
          <p className="mt-4">
            Your presence is determined by:
          </p>        
          <ul>
            <li>
              Amount of {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs owned
            </li>
            <li>
              Earlier Periods of Minted {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs have a higher presence multiplier
            </li>
            <li>
              Task and Reward NFTs earn you additional presence
            </li>                  
          </ul>
        </Col>
        <Col sm={12} md={12} lg={4} xl={5} xxl={5} >
          <ReactPlayer id="media-token" url={mediaTokenFilePath} width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} playsinline={true}/>
        </Col>
      </Row>
      <Stats 
        myTokensRefresh={myTokensRefresh} 
        showStatsLimit={showStatsLimit} 
        chainDisplayName={chainDisplayName}
        currencySymbol={currencySymbol}
      />
      <form>
        <h2 >Step 1: Commit your {currencySymbol === "" ? "tokens" : currencySymbol}{(walletEthBalance.length > 0) ? (": " + Number(walletEthBalance).toLocaleString(undefined, { maximumFractionDigits: 2 })) : ("") }</h2>
        <ul>
          {mintCost == null ? "" : <li>Each {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFT costs {mintCost} {currencySymbol} </li>}
          <li>Enter the quantity of {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs to mint</li>        
        </ul>
        <Button id="maxButton" className="mb-1" onClick={onMaxPressed}>
          max
        </Button>
        <div className="d-flex">
          <input
            type="text"
            placeholder="1000"
            onChange={onHandleAmountChange}
            value={amount}
            className="w-50"
          /> 
          {currencySymbol} {mintCost == null ? "" : " = " + amount*mintCost + " " + currencySymbol} 
        </div>                           
        <h2 className="mt-4">Step 2: Mint {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs</h2>
        {isMinting ? (<BarLoader id="mintBarLoader" color="#f3b033"></BarLoader>) : (
          <div>
            <Button 
              className="mt-3"
              variant="primary" 
              onClick={onMintPressed} 
            >
              Mint         
            </Button>
          </div>          
        )}
        <br />
        <br />
        <Task1 
          walletAddress={walletAddress} 
          walletNetworkCorrect={walletNetworkCorrect} 
          setStatus={setStatus} 
          setAlertVariant={setAlertVariant} 
          setMyTokensRefresh={setMyTokensRefresh}
          contractAddress={contractAddress}
          blockExplorerUrl={blockExplorerUrl}
          contractABI={contractABI}
          chainDisplayName={chainDisplayName}
          byteCTA_ApiUrlRegister={byteCTA_ApiUrlRegister}
          byteCTA_ApiUrlStatus={byteCTA_ApiUrlStatus}
          byteCTA_ApiFunctionKey={byteCTA_ApiFunctionKey}
          byteCTA_ApiKey={byteCTA_ApiKey}
          byteCTA_TaskT1Enabled={byteCTA_TaskT1Enabled}
          byteCTA_TaskT1Message={byteCTA_TaskT1Message}
          byteCTA_TaskT1MessagePopover={byteCTA_TaskT1MessagePopover}
          byteCTA_TaskT1Key={byteCTA_TaskT1Key}
        />
        <Task2 
          walletAddress={walletAddress} 
          walletNetworkCorrect={walletNetworkCorrect} 
          myTokensRefresh={myTokensRefresh} 
          setStatus={setStatus} 
          setAlertVariant={setAlertVariant} 
          setMyTokensRefresh={setMyTokensRefresh}
          blockExplorerUrl={blockExplorerUrl}
          chainDisplayName={chainDisplayName}
          taskT2Enabled={taskT2Enabled}
          taskT2TokenID={taskT2TokenID}
        />
        <Task3 
          walletAddress={walletAddress} 
          walletNetworkCorrect={walletNetworkCorrect} 
          myTokensRefresh={myTokensRefresh} 
          contractAddress={contractAddress}
          blockExplorerUrl={blockExplorerUrl}
          contractABI={contractABI}          
          setStatus={setStatus} 
          setAlertVariant={setAlertVariant} 
          setMyTokensRefresh={setMyTokensRefresh}
          taskT3TokenID={taskT3TokenID}
          chainsCount={chainsName.length}
          chainName={chainName}
          byteCTA_ApiUrlStatus={byteCTA_ApiUrlStatus}
          byteCTA_ApiFunctionKey={byteCTA_ApiFunctionKey}
          byteCTA_ApiKey={byteCTA_ApiKey}
          byteCTA_TaskT3Enabled={byteCTA_TaskT3Enabled}
          byteCTA_TaskT3Key={byteCTA_TaskT3Key}          
        />               
      </form>
      {(walletNetworkCorrect)?(
      <MyTokens 
        address={walletAddress} 
        myTokensRefresh={myTokensRefresh} 
        contractAddress={contractAddress} 
        blockExplorerUrl={blockExplorerUrl} 
        chainDisplayName={chainDisplayName}
      />
      ):("")}
      <Footer />
    </div>
  );
};

export default Minter;
