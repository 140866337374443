import { useEffect, useState } from "react";
import {switchEthereumChain} from "./utils/interact";
import {byteCTA_AddressHasClaimed, byteCTA_MintTaskClaim, byteCTA_GetTaskStatus} from "./utils/bytecta";

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { BarLoader } from "react-spinners";
import urlJoin from 'url-join';
import Alert from "react-bootstrap/Alert";

const Task3 = (props) => {

  const chainTarget = "eth";
  const blockExplorerUrl = props.blockExplorerUrl;
  const chainName = props.chainName;
  const contractAddress = props.contractAddress;
  const contractABI = props.contractABI;

  const taskTokenID = props.taskT3TokenID;
  const chainsCount = props.chainsCount;

  const byteCTA_ApiUrlStatus = props.byteCTA_ApiUrlStatus; 
  const byteCTA_ApiFunctionKey = props.byteCTA_ApiFunctionKey;

  const byteCTA_ApiKey = props.byteCTA_ApiKey;
  const byteCTA_TaskKey = props.byteCTA_TaskT3Key;  
  const byteCTA_TaskT3Enabled = props.byteCTA_TaskT3Enabled;    

  const [walletAddress, setWalletAddress] = useState("");
  const [walletNetworkCorrect, setWalletNetworkCorrect] = useState("");    

  const [isMinting, setIsMinting] = useState(false);

  const [taskSectionEnabled, setTaskSectionEnabled] = useState(false);
  const [taskSectionCheckStatusEnabled, setTaskSectionCheckStatusEnabled] = useState(true);
  const [taskSectionClaimNFTEnabled, setTaskSectionClaimNFTEnabled] = useState(false);
  const [taskClaimNFTButtonDisabled, setTaskClaimNFTButtonDisabled] = useState(true);
  const [taskSectionClaimNetworkIncorrect, setTaskSectionClaimNetworkIncorrect] = useState(true);
  const [taskT3ClaimApproved, setTaskT3ClaimApproved] = useState(false);
  const [taskT3ClaimCompleted, setTaskT3ClaimCompleted] = useState(false);
  const [claimToken, setClaimToken] = useState("");
  const [claimTokenID, setClaimTokenID] = useState("");

  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  
  const statusMessage = {
    wrongNetwork: "Wrong Network",
    connectWallet: "Connect Your Wallet",
  };

  useEffect(() => { 
    if(byteCTA_TaskT3Enabled){
      setTaskSectionEnabled(true);       
     
      if((chainName !== chainTarget)){   
        setTaskSectionClaimNetworkIncorrect(true);
        setTaskSectionClaimNFTEnabled(false);
        setTaskClaimNFTButtonDisabled(true);
        setTaskSectionCheckStatusEnabled(false);
      }else if(taskT3ClaimCompleted){
        setTaskSectionClaimNetworkIncorrect(false);
        setTaskSectionClaimNFTEnabled(false);   
        setTaskClaimNFTButtonDisabled(true);
        setTaskSectionCheckStatusEnabled(false);
      }else{
        setTaskSectionClaimNetworkIncorrect(false);
        setTaskSectionClaimNFTEnabled(true);

        if(taskT3ClaimApproved && !taskT3ClaimCompleted){
          setTaskClaimNFTButtonDisabled(false);
          setTaskSectionCheckStatusEnabled(false);

        } else{
          setTaskClaimNFTButtonDisabled(true);
          setTaskSectionCheckStatusEnabled(true);
        }
      }               
    }else{
      setTaskSectionEnabled(false);
    }     
  }, [byteCTA_TaskT3Enabled, taskT3ClaimApproved, taskT3ClaimCompleted, chainName]);
    
  useEffect(() =>{
    setWalletAddress(props.walletAddress);
    setWalletNetworkCorrect((props.walletNetworkCorrect && chainName === chainTarget) ? true : false);
    
    const getAddressAddressHasClaimedUseEffect = async () => {
      let result = (await byteCTA_AddressHasClaimed(contractABI, contractAddress, taskTokenID));
      if(result.success){
        setTaskT3ClaimCompleted(result.result);
      }
    }

    if(chainName === chainTarget){
      getAddressAddressHasClaimedUseEffect();
    }
  }, [props.walletAddress, props.walletNetworkCorrect, props.myTokensRefresh, contractABI, contractAddress, chainName, chainTarget, taskTokenID]);
    
  async function onMintTransactionUpdateAsync(success, status, txHash){
    setIsMinting(false);
    if(success){
      props.setAlertVariant("success");   
      props.setStatus(getTransactionAlertStatusProcessing(txHash, true));
      setTaskT3ClaimCompleted(true);

      setTimeout(props.setMyTokensRefresh(Math.random()), 5000);
    }else{
      props.setAlertVariant("danger");  
      props.setStatus(status);
    }
  }

  const onMintTaskPressed = async () => {

    let statusMintPressed = "";
    props.setStatus(statusMintPressed);
    
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true && chainName === chainTarget){

        if(taskT3ClaimApproved && !taskT3ClaimCompleted){

        //Mint
        //Set status
        setIsMinting(true);
        const mintResult = await byteCTA_MintTaskClaim(contractABI, contractAddress, claimTokenID, claimToken, onMintTransactionUpdateAsync);
        statusMintPressed = mintResult.status;
        
        if(mintResult.success){
          props.setAlertVariant("info");
          props.setStatus(getTransactionAlertStatusProcessing(mintResult.txHash, false));
        }else{
          setIsMinting(false);
          props.setAlertVariant("danger");
          if(statusMintPressed.includes("Already Claimed")){
            setTaskT3ClaimCompleted(true);
            props.setStatus("Already Claimed");
          }else{
            props.setStatus(statusMintPressed);
          }
          console.log(statusMintPressed)
        }             
      }          
    }else if (window.ethereum && walletAddress.trim().length !== 0 && (walletNetworkCorrect !== true || chainName !== chainTarget)){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }    
  };
 
  const onCheckStatusButtonPressed = async () => {
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true && chainName === chainTarget){
      setIsCheckingStatus(true);

      let postTaskResult = await byteCTA_GetTaskStatus(byteCTA_ApiFunctionKey, byteCTA_ApiUrlStatus, byteCTA_ApiKey, byteCTA_TaskKey, true);

      props.setStatus(postTaskResult.status);

      if(postTaskResult.success){
        props.setAlertVariant("success");
        if(postTaskResult.taskClaimCompleted){
          props.setStatus("Already Claimed");
        }
        else if(postTaskResult.taskClaimApproved){
          props.setStatus("Ready To Claim");
        }else{
          props.setStatus("Must Complete Cartesian NFT");
        }
        setTaskT3ClaimApproved(postTaskResult.taskClaimApproved);
        setTaskT3ClaimCompleted(postTaskResult.taskClaimCompleted);
        setClaimToken(postTaskResult.claimToken);   
        setClaimTokenID(postTaskResult.tokenID);
      }else{
        props.setAlertVariant("danger");

        if(postTaskResult.status.includes("Not Found")){
          props.setStatus("Must Complete Cartesian NFT"); 
        }else{
          props.setStatus(postTaskResult.status);           
        }          
      } 
      setIsCheckingStatus(false);
    }
    else if (window.ethereum && walletAddress.trim().length !== 0 && (walletNetworkCorrect !== true || chainName !== chainTarget)){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }        
  };    
  function getTransactionAlertStatusProcessing(txHash, isComplete){
    const txHashURL = urlJoin(blockExplorerUrl, "tx", txHash);
    const statusMessageTransactionReceipt = {
        statusSuccess: (
            <div>
                <Alert.Heading>Success</Alert.Heading>
                See Transaction:                 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
        statusProcessing: (
            <div>
                <Alert.Heading>Processing</Alert.Heading>
                See Transaction: 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
    };      
    
    if(isComplete){
      return statusMessageTransactionReceipt.statusSuccess;
    }else{
      return statusMessageTransactionReceipt.statusProcessing;
    }
  }
  
  const onSwitchEthereumChain = event => {
      switchEthereumChain("eth")
  };

  return(
  <div className={taskSectionEnabled ? ("") : ("d-none")} >
      <h2 className="mt-5">Task: Complete Cartesian NFT -{">"} Claim NFT</h2>
      <ul>
        <li>Mint blockchain Bonfire NFTs on all {chainsCount} chains</li>
        <li>Check Status, and claim your free reward NFT (x10000 presence multiplier)</li>
        <li>You may claim this reward once, only on the ETH chain</li>
      </ul>
        <div className="d-inline-block">        
          {!taskSectionClaimNFTEnabled ? ("") : (
            isMinting ? (<BarLoader id="mintBarLoader" color="#f3b033"></BarLoader>) :
            (<Button 
              className="mt-3"
              variant="primary" 
              onClick={onMintTaskPressed}
              disabled={taskClaimNFTButtonDisabled} 
            >
              Claim NFT         
            </Button>)
          )} 
          {!taskSectionCheckStatusEnabled ? ("") : (
            isCheckingStatus ? (<BarLoader color="#f3b033" className="mt-3 ms-3"></BarLoader>) :
              (<Button 
              className="mt-3 ms-3"
              variant="primary" 
              onClick={onCheckStatusButtonPressed} 
            >
              Check Status         
            </Button>)
          )}             
          {!taskSectionClaimNetworkIncorrect ? ("") : (
            <Button 
              className="mt-3"
              variant="secondary" 
              onClick={onSwitchEthereumChain} 
            >
              Switch To Ethereum         
            </Button>
          )}           
          {!taskT3ClaimCompleted ? ("") : (
            <Badge className="mt-2" bg="success">
              <h5 variant="success">Completed</h5>
            </Badge>
          )}             
        </div>          
    </div>            
  );
};

export default Task3;