import { useEffect, useState } from "react";
import {getAddressMintDetails, mint_TaskBonus} from "./utils/interact";

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { BarLoader } from "react-spinners";
import urlJoin from 'url-join';
import Alert from "react-bootstrap/Alert";

const Task2 = (props) => {

  const blockExplorerUrl = props.blockExplorerUrl;
  const chainDisplayName = props.chainDisplayName;

  const taskT2TokenID = props.taskT2TokenID;
  
  const [walletAddress, setWalletAddress] = useState("");
  const [walletNetworkCorrect, setWalletNetworkCorrect] = useState("");    

  const [isMinting, setIsMinting] = useState(false);

  const [addressMintCount, setAddressMintCount] = useState(0);
  const [taskThreshold, setTaskThreshold] = useState(0);
  const [taskAddressHasMinted, setTaskAddressHasMinted] = useState(false);
  const [taskClaimCompleted, setTaskClaimCompleted] = useState(false);
  const [taskSectionEnabled, setTaskSectionEnabled] = useState(false);
  const [taskSectionClaimNFTEnabled, setTaskSectionClaimNFTEnabled] = useState(false);
  const [taskClaimNFTButtonDisabled, setTaskClaimNFTButtonDisabled] = useState(false);

  const statusMessage = {
    wrongNetwork: "Wrong Network",
    connectWallet: "Connect Your Wallet",
  };

  useEffect(() => { 
    if(props.taskT2Enabled){
      setTaskSectionEnabled(true);       
     
      if((addressMintCount >= taskThreshold) && !taskAddressHasMinted  && taskThreshold > 0){          
        setTaskSectionClaimNFTEnabled(true);
        setTaskClaimNFTButtonDisabled(false);
        setTaskClaimCompleted(false);
      }else if(taskAddressHasMinted){
        setTaskSectionClaimNFTEnabled(false);   
        setTaskClaimNFTButtonDisabled(true);
        setTaskClaimCompleted(true);
      }else{
        setTaskSectionClaimNFTEnabled(true);
        setTaskClaimNFTButtonDisabled(true);
        setTaskClaimCompleted(false);
      }               
    }else{
      setTaskSectionEnabled(false);
    }     
  }, [props.taskT2Enabled, addressMintCount, taskThreshold, taskAddressHasMinted]);
    
  useEffect(() =>{
    setWalletAddress(props.walletAddress);
    setWalletNetworkCorrect(props.walletNetworkCorrect);
    
    const getAddressMintDetailsUseEffect = async () => {
      let resultGetAddressMintDetails = (await getAddressMintDetails(taskT2TokenID));
      if(resultGetAddressMintDetails.success){
        setTaskThreshold(Number(resultGetAddressMintDetails.result.taskThreshold));
        setAddressMintCount(Number(resultGetAddressMintDetails.result._addressMintCount));
        setTaskAddressHasMinted(resultGetAddressMintDetails.result.taskAddressHasMinted);
      }
    }

    getAddressMintDetailsUseEffect();
  }, [props.walletAddress, props.walletNetworkCorrect, props.myTokensRefresh]);
    
  async function onMintTransactionUpdateAsync(success, status, txHash){
    setIsMinting(false);
    if(success){
      props.setAlertVariant("success");   
      props.setStatus(getTransactionAlertStatusProcessing(txHash, true));
      setTaskClaimCompleted(true);

      setTimeout(props.setMyTokensRefresh(Math.random()), 5000);
    }else{
      props.setAlertVariant("danger");  
      props.setStatus(status);
    }
  }

  const onMintTaskPressed = async () => {

    let statusMintPressed = "";
    props.setStatus(statusMintPressed);
    
    if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect === true){

      if((addressMintCount >= taskThreshold) && !taskAddressHasMinted){
        //Mint
        //Set status
        setIsMinting(true);
        const mintResult = await mint_TaskBonus(taskT2TokenID, onMintTransactionUpdateAsync);
        statusMintPressed = mintResult.status;
        
        if(mintResult.success){
          props.setAlertVariant("info");
          props.setStatus(getTransactionAlertStatusProcessing(mintResult.txHash, false));
        }else{
          setIsMinting(false);
          props.setAlertVariant("danger");
          if(statusMintPressed.includes("Already Claimed")){
            setTaskClaimCompleted(true);
            props.setStatus("Already Claimed");
          }else{
            props.setStatus(statusMintPressed);
          }
          console.log(statusMintPressed)
        }             
      }          
    }else if (window.ethereum && walletAddress.trim().length !== 0 && walletNetworkCorrect !== true){
      props.setStatus(statusMessage.wrongNetwork);
      props.setAlertVariant("danger");           
    }else if (window.ethereum){
      props.setStatus(statusMessage.connectWallet);
      props.setAlertVariant("danger");           
    }    
  };
 
  function getTransactionAlertStatusProcessing(txHash, isComplete){
    const txHashURL = urlJoin(blockExplorerUrl, "tx", txHash);
    const statusMessageTransactionReceipt = {
        statusSuccess: (
            <div>
                <Alert.Heading>Success</Alert.Heading>
                See Transaction:                 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
        statusProcessing: (
            <div>
                <Alert.Heading>Processing</Alert.Heading>
                See Transaction: 
                <Alert.Link target="_blank" href={txHashURL} rel="noreferrer">
                  {
                    String(txHash).substring(0, 6) +
                    "..." +
                    String(txHash).substring(txHash.length - 4)
                  }
                </Alert.Link>
            </div>),
    };      
    
    if(isComplete){
      return statusMessageTransactionReceipt.statusSuccess;
    }else{
      return statusMessageTransactionReceipt.statusProcessing;
    }
  }

  return(
  <div className={taskSectionEnabled ? ("") : ("d-none")} >
      <h2 className="mt-5">Task: Mint {taskThreshold} -{">"} Claim NFT</h2>
      <ul>
        <li>Mint at least {taskThreshold} {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire NFTs</li>
        <li>You have minted <span className={(addressMintCount < taskThreshold) ? ("text-warning fs-5") : ("text-success fs-5")}>{addressMintCount}</span> of {taskThreshold}</li>
        <li>Claim your free {chainDisplayName === "" ? "blockchain" : chainDisplayName + " "}Bonfire Reward NFT (x1000 presence multiplier)</li>
        <li>You may claim this reward once per chain</li>
      </ul>
        <div>
          {!taskSectionClaimNFTEnabled ? ("") : (
              isMinting ? (<BarLoader id="mintBarLoader" color="#f3b033"></BarLoader>) :
              (<Button 
                className="mt-3"
                variant="primary" 
                onClick={onMintTaskPressed}
                disabled={taskClaimNFTButtonDisabled} 
              >
                Claim NFT         
              </Button>)
          )} 
          {!taskClaimCompleted ? ("") : (
          <Badge className="mt-2" bg="success">
            <h5 variant="success">Completed</h5>
          </Badge>
          )}             
        </div>          
    </div>            
  );
};

export default Task2;